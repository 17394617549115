import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import Logo from "assets/images/BDILogo.png"

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={'/'}>
        <img src={Logo} loading="lazy" alt="logo" />
    </ButtonBase>
);

export default LogoSection;
