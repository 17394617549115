import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import nacl from "tweetnacl"
import naclUtil from 'tweetnacl-util'

function historyPush(val: string) {
  // const history = useHistory()
  return window.location.replace(val)
}

const baseAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const refreshTokenUrl = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const _insertSignature = async (config: any, token?: string) => {
  if (token) {
    config.headers.Authorization = 'Bearer ' + token

    console.log('check header authorization', config.headers.Authorization)
    // config.url = `/api${config.url}`

    // KONDISI JIKA TIDAK BUTUH SIGNATURE
    if (config.url?.includes("signature-key")) return config
    if (config.headers.Authorization === "Bearer undefined" || config.headers.Authorization === "Bearer null") return config

    const res = await _generateKey()
    console.log('generate key', res)
    const signature = _generateSignature({ key: res.data, config })
    config.headers["X-Signature"] = signature
    return config
  } else {
    return config
  }
}

const _isEmpty = (data: any) => {
  return data && Object.keys(data).length === 0
}

const _generateSignature = (data: any) => {
  const config = data.config
  const { key, request_id } = data.key

  const isEmptyObj = _isEmpty(config.data)
  const uri = config?.data?.requestUrl ? `/api${config?.data?.requestUrl}` : `/api${config.url}`
  const stringToHash = uri.concat(isEmptyObj || isEmptyObj === undefined ? '' : JSON.stringify(config.data))
  console.log(key)
  const requestKey = naclUtil.decodeBase64(key);
  console.log('request key', requestKey)
  const formattedStringToHash = naclUtil.decodeUTF8(stringToHash);
  const hashedStringToHash = nacl.hash(formattedStringToHash);
  const keys = nacl.box.keyPair();
  const nonce = nacl.randomBytes(nacl.box.nonceLength);
  const encryptedStringToHash = nacl.box(hashedStringToHash, nonce, requestKey, keys.secretKey);
  const endcodedStringToHash = naclUtil.encodeBase64(encryptedStringToHash);

  const RequestNonce = naclUtil.encodeBase64(nonce);
  const RequestKey = naclUtil.encodeBase64(keys.publicKey);

  const prepareSignature = {
    RequestId: request_id,
    RequestKey: RequestKey,
    RequestNonce: RequestNonce,
    RequestData: endcodedStringToHash,
  };
  const decodeUTF8Signature = naclUtil.decodeUTF8(JSON.stringify(prepareSignature))
  const Signature = naclUtil.encodeBase64(decodeUTF8Signature);
  return Signature
}

const _generateKey = async () => {
  const requestId = uuidv4()
  const response = await baseAxios.get(`/community/v1/custom/generate/signature-key/${requestId}`)
  return response
}

const newService = axios.create()
baseAxios.interceptors.request.use(async (config) => {
  console.log("🚀 ~ file: axios.tsx:18 ~ baseAxios.interceptors.request.use ~ config", config)
  let session: any = localStorage.getItem('session')
  session = session ? JSON.parse(session) : null
  const token = session?.token?.access_token
  const requestOptions = await _insertSignature(config, token)
  return requestOptions
}, function (error) {
  return Promise.reject(error)
})

let isRefreshing = false;
let failedQueue: any = [];
console.log("🚀 ~ file: axios.tsx:36 ~ failedQueue", failedQueue)

const processQueue = (error: any, token = null) => {
  failedQueue?.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

refreshTokenUrl.interceptors.request.use(async (config) => {
  let session: any = localStorage.getItem('session')
  session = session ? JSON.parse(session) : null
  const token = session?.token?.refresh_token
  config.data = {
    refresh_token: token
  }
  // const requestOptions = await _insertSignature(config, token)
  return config
}, function (error) {
  return Promise.reject(error)
})
function handleRefreshToken(error: any, originalRequest: any) {
  return new Promise(function (resolve, reject) {
    refreshTokenUrl.post('/customer/v1/user/refresh-token')
      .then(async resdata => {
        console.log("🚀 ~ file: axios.tsx:60 ~ resdata", resdata)
        if (resdata.status === 200) {
          // Save token to localStorage
          const newToken = { ...session, token: resdata?.data?.data }
          window.localStorage.setItem('session', JSON.stringify(newToken));

          // retry request to original 
          if (resdata.data.success === true) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + resdata.data?.data?.access_token;
            originalRequest.headers['Authorization'] = 'Bearer ' + resdata.data?.data?.access_token;
            processQueue(null, resdata?.data?.data?.access_token);
            resolve(newService(originalRequest));
          } else {
            processQueue(resdata.data, null);
            reject(resdata.data);
          }
        } else {
          console.log("🚀 ~ file: interceptor.js ~ line 31 ~ err", resdata.data)
          processQueue(resdata.data, null);
          reject(resdata.data);
          historyPush('/signin')
        }
      })
      .catch((err) => {
        console.log("🚀 ~ file: interceptor.js ~ line 31 ~ err", err.response, err)
        processQueue(err.response, null);
        resolve({ data: { message: 'Session Expired' } });
        // historyPush('/login')
      })
      .finally(() => { isRefreshing = false })
  })
}

baseAxios.interceptors.response.use((response) => {
  return response.data ? response.data : response
}, async (error) => {
  console.log("Interceptor ~ Error Response", error.response)
  console.log("Interceptor ~ Error", error)
  const originalRequest = error.config;
  const { response = {} } = error
  const { data } = response
  // console.log("🚀 ~ file: axios.tsx:25 ~ baseAxios.interceptors.response.use ~ error", error, originalRequest)
  if (response?.config?.url === "/customer/v1/user/login") {
    return data
  } else {
    if (response?.config?.url !== `${process.env.REACT_APP_API_URL}/customer/v1/user/login` && response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        console.log("IS REFRESHING")
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        }).then(token => {
          console.log("🚀 ~ file: axios.tsx:40 ~ token", token)
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return newService(originalRequest);
        }).catch(err => {
          console.log("🚀 ~ file: axios.tsx:44 ~ err", err)
          return Promise.reject(err);
        })
      } else {
        console.log('ELSE IS REFRESHING')
        originalRequest._retry = true;
        isRefreshing = true;

        const testres: any = await handleRefreshToken(response, originalRequest)
        return testres?.data;
      }
    }
    if (response?.config?.url !== `${process.env.REACT_APP_API_URL}/internaluser/v1/user/login` && response?.status === 403) {
      console.log("ERROR 403")
      // historyPush('/signin')
      return data;
    }

    console.log(response)
    const resp = {
      data: {
        message: 'Network Error'
      },
      status: 400,
      message: 'Network Error'
    }
    return Promise.reject(data ?? resp)
  }
})

export default baseAxios