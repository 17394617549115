import * as actionTypes from '../../../constants/actions'

const initialState = {
    open: false
}

const loading = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.LOADING_ACTIVE:
            return state = {...state, open: true}
        case actionTypes.LOADING_INACTIVE:
            return state = {...state, open: false}
        default: return state
    }
}

export default loading