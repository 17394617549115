import { lazy } from 'react';

import MainLayout from "../layouts/MainLayout";

import Loadable from "../components/Loadable";
import { RouteObject } from 'react-router-dom';

const ListBulkUpload = Loadable(lazy(() => import('../pages/mainlayout/bulkUpload/list')))
const FormBulkUpload = Loadable(lazy(() => import('../pages/mainlayout/bulkUpload/form')))

const ListReport = Loadable(lazy(() => import('../pages/mainlayout/report/list')))
const FormReport = Loadable(lazy(() => import('../pages/mainlayout/report/form')))

const Dashboard = Loadable(lazy(() => import('../pages/mainlayout/dashboard')))

const MainRoutes:RouteObject = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'list',
            children: [
                {
                    path: 'bulk-upload',
                    element: <ListBulkUpload />
                },
                {
                    path: 'report',
                    element: <ListReport />
                },
            ]
        },
        {
            path: 'form',
            children: [
                {
                    path: 'bulk-upload',
                    element: <FormBulkUpload />,
                    children: [
                        {
                            path: ':id',
                            element: <FormBulkUpload />
                        }
                    ]
                },
                {
                    path: 'report',
                    element: <FormReport />,
                    children: [
                        {
                            path: ':id',
                            element: <FormReport />
                        }
                    ]
                },
            ]
        },
    ]
};

export default MainRoutes;
