import { useSelector } from "react-redux"
import Loading from 'assets/lotties/loading.json'
import Lottie from "react-lottie"
const BackdropComponent = () => {
    const { open } = useSelector((state:any) => state.loading)
    console.log("🚀 ~ file: Backdrop.tsx:5 ~ BackdropComponent ~ open", open)

    return (
      <div className={open ? "z-50 absolute w-[100vw] h-[100vh] bg-black/50 backdrop-filter: blur(20px) flex justify-center items-center animate-fade-in-down" : "hidden"}>
        <Lottie options={{loop: true, autoplay: true, animationData: Loading}} height="150px" />
      </div>
    )
}

export default BackdropComponent